import React from 'react'
import { TbBrandTwitter } from "react-icons/tb";
import { MdOutlineAlternateEmail } from "react-icons/md";


const Hero = () => {
  return (
    <div className='flex h-screen hero p-10'> 
        <div className='flex flex-col md:flex-row m-auto w-[300px]'>
            <div className='w-[150px] md:w-[40rem] mb-5 md:mr-5'>
                <img src="/avatar.png" alt="Avatar" />
            </div>
            <div className='m-auto text-lg'>
                <h1 className='text-5xl text-black mb-4'><span className='wave mb-6'>👋</span> Hello!</h1>
            </div>
            
        </div>
        <div className='flex fixed right-10 top-10 text-2xl w-[60px] justify-between'><a href="https://twitter.com/anees_io"><TbBrandTwitter/></a><a href="mailto:hello@anees.io"><MdOutlineAlternateEmail/></a></div>

    </div>
  )
}

export default Hero